<template lang='pug'>
  v-container(fluid)
    v-skeleton-loader.mx-auto(v-if="!item" max-width="500" type="card")
    watch-videos(v-else :model ='item')
</template>
<script>
  import axios from 'axios'
  import loaders from '@/plugins/mixins/loaders'
  import firebase from 'firebase'
  import * as vars from '@/vars.json'
  const formatter = new Intl.NumberFormat('es-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
    minimumIntegerDigits: 1,
  })
  export default {

    filters: {
      moneyFormat: function (value) {
        const entero = value.toString().slice(0, -2)
        const decimal = value.toString().slice(-2)
        return formatter.format(entero.concat('.', decimal))
      },
    },

    components: {
      videowatch: () => import('../VideoWatch'),
      WatchVideos: () => import('@/components/custom/dialogs/Videos/WatchDialog.vue'),
    },

    mixins: [loaders],

    data: () => ({
      item: '',
    }),

    created () {
      const vm = this
      vm.sesionId = {
        id: vm.$route.params.id,
      }
      axios.post(vars.urlProd + '/getCheckout', vm.sesionId).then(res => {
        // const checkout = res.data.checkout.display_items[0]
        const metadata = res.data.checkout.metadata
        // Item desde base de datos
        firebase.database().ref(`media/${metadata.id}`).orderByChild('name').once('value', function (snapshot) {
          vm.item = snapshot.val()
        })
        vm.$store.dispatch('downloads/createDownloadsCheckout', metadata)
        // vm.loading = false
      }).catch(e => {
        console.log(e)
        // vm.$router.push('/')
      })
    },

    methods: {
      goDashboard () {
        const vm = this
        vm.$router.push('/')
      },
    },
  }
</script>
